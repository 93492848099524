import {Controller} from 'stimulus';

import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';

export default class extends Controller {
  static values = {
    order: Number,
    sorting: String,
    pageLength: Number,
  }

  connect() {
    this.initValues();
    this.initDatatable();
  }

  initValues() {
    if (this.sortingValue === '') {
      this.sortingValue = 'asc';
    }

    if (this.orderValue === 0) {
      this.orderValue = 0;
    }

    if (this.pageLengthValue === 0) {
      this.pageLengthValue = 25;
    }
  }

  initDatatable() {
    if ($.fn.dataTable.isDataTable(this.element) === false) {
      $(this.element).dataTable({
        'pageLength': this.pageLengthValue,
        'order': [[this.orderValue, this.sortingValue]],
        'language': {
          'sEmptyTable': 'Keine Daten in der Tabelle vorhanden',
          'sInfo': '_START_ bis _END_ von _TOTAL_ Einträgen',
          'sInfoEmpty': 'Keine Daten vorhanden',
          'sInfoFiltered': '(gefiltert von _MAX_ Einträgen)',
          'sInfoPostFix': '',
          'sInfoThousands': '.',
          'sLengthMenu': '_MENU_ Einträge anzeigen',
          'sLoadingRecords': 'Wird geladen ..',
          'sProcessing': 'Bitte warten ..',
          'sSearch': 'Suchen',
          'sZeroRecords': 'Keine Einträge vorhanden',
          'oPaginate': {
            'sFirst': 'Erste',
            'sPrevious': 'Zurück',
            'sNext': 'Nächste',
            'sLast': 'Letzte',
          },
          'oAria': {
            'sSortAscending': ': Spalte aufsteigend zu sortieren',
            'sSortDescending': ': Spalte absteigend zu sortieren',
          },
          'select': {
            'rows': {
              '_': '%d Zeilen ausgewählt',
              '0': '',
              '1': '1 Zeile ausgewählt',
            },
          },
          'buttons': {
            'print': 'Drucken',
            'colvis': 'Spalten',
            'copy': 'Kopieren',
            'copyTitle': 'In Zwischenablage kopieren',
            'copyKeys': 'CopyKeys',
            'copySuccess': {
              '_': '%d Zeilen kopiert',
              '1': '1 Zeile kopiert',
            },
            'pageLength': {
              '-1': 'Zeige alle Zeilen',
              '_': 'Zeige %d Zeilen',
            },
          },
        },
      });
    }
  }
}
