/* eslint-disable no-invalid-this */
import {Controller} from 'stimulus';

import $ from 'jquery';
import 'metismenu';

export default class extends Controller {
  connect() {
    $(this.element).metisMenu();

    $('.mobile-toggle-nav').click(function() {
      $(this).toggleClass('is-active');
      $('.app-container').toggleClass('sidebar-mobile-open');
    });

    $('.mobile-toggle-header-nav').click(function() {
      $(this).toggleClass('active');
      $('.app-header__content').toggleClass('header-mobile-open');
    });

    $('.mobile-app-menu-btn').click(function() {
      $('.hamburger', this).toggleClass('is-active');
      $('.app-inner-layout').toggleClass('open-mobile-menu');
    });

    $('.close-sidebar-btn').click(function() {
      const classToSwitch = $(this).attr('data-class');
      const containerElement = '.app-container';
      $(containerElement).toggleClass(classToSwitch);

      const closeBtn = $(this);

      if (closeBtn.hasClass('is-active')) {
        closeBtn.removeClass('is-active');
      } else {
        closeBtn.addClass('is-active');
      }
    });
  }
}
