import {Controller} from 'stimulus';
import Swal from 'sweetalert2';
import {useDispatch} from 'stimulus-use';

export default class extends Controller {
    static values = {
      title: String,
      text: String,
      icon: String,
      confirmButtonText: String,
      confirmButtonColor: String,
      cancelButtonText: String,
      cancelButtonColor: String,
      showCancelButton: Boolean,
      submitAsync: Boolean,
      successTitle: String,
      successHtml: String,
    }

    connect() {
      useDispatch(this, {debug: true});
    }

    onSubmit(event) {
      event.preventDefault();

      Swal.fire({
        title: this.titleValue || null,
        text: this.textValue || null,
        icon: this.iconValue || null,
        showCancelButton: this.showCancelButtonValue || true,
        confirmButtonText: this.confirmButtonTextValue || 'Ja',
        cancelButtonText: this.cancelButtonTextValue || 'Nein',
        confirmButtonColor: this.confirmButtonColorValue || '#5cd72f',
        cancelButtonColor: this.cancelButtonColor || '#d33',
        showLoaderOnConfirm: true,
        focusCancel: true,
        preConfirm: () => {
          return this.submitForm();
        },
      });
    }

    async submitForm() {
      if (!this.submitAsyncValue) {
        this.element.submit();

        return;
      }

      const response = await fetch(this.element.action, {
        method: this.element.method,
        body: new URLSearchParams(new FormData(this.element)),
      });

      this.dispatch('async:submitted:success', {response});

      await Swal.fire(this.successTitleValue || '',
          this.successHtmlValue || '',
          'success',
      );
    }
}
