import {Controller} from 'stimulus';

export default class extends Controller {
    static values = {
      url: String,
      timeout: Number,
    }

    redirect() {
      const url = this.urlValue;

      setTimeout(function() {
        location.href = url;
      }, this.timeoutValue || 0);
    }
}
