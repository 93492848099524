import {Controller} from 'stimulus';

export default class extends Controller {
    static targets = ['content'];

    static values = {
      url: String,
      contentId: String,
    };

    async refreshContent(event) {
      let elementToRefresh;

      if (!this.hasContentTarget) {
        elementToRefresh = document.getElementById(this.contentIdValue);
      } else {
        elementToRefresh = this.contentTarget;
      }

      elementToRefresh.style.opacity = '0.5';

      const response = await fetch(this.urlValue);

      elementToRefresh.innerHTML = await response.text();
      elementToRefresh.style.opacity = 1;
    }

    reloadPage() {
      setTimeout(function() {
        location.reload();
      }, 1000);
    }
}
