import {Controller} from 'stimulus';
import {Modal} from 'bootstrap';
import $ from 'jquery';

export default class extends Controller {
    modal = null

    static targets = [
      'modal',
      'modalBody',
    ]

    static values = {
      url: String,
    }

    async openModal() {
      this.modalBodyTarget.innerHTML = 'Lade...';

      this.modal = new Modal(this.modalTarget, {
        backdrop: 'true' === this.modalTarget.dataset.backdrop,
      });

      this.modal.show();

      this.modalBodyTarget.innerHTML = await $.ajax(this.urlValue);
    }
}

