import {Controller} from 'stimulus';

export default class extends Controller {
    static targets = ['notificationContainer']
    static values = {
      endpoint: String,
    }

    connect() {
      this.getUnreadNotifications();
    }

    async getUnreadNotifications() {
      const response = await fetch(this.endpointValue);
      this.element.innerHTML = await response.text();
    }

    maskAsRead(event) {
      event.stopPropagation();

      const notificationItem = event.currentTarget.parentElement;
      const endpoint = event.currentTarget.dataset.endpoint;

      $.ajax({
        url: endpoint,
        method: 'POST',
      });

      notificationItem.classList.add('removing');

      setTimeout(function() {
        notificationItem.remove();
      }, 500);
    }
}
