import {Controller} from 'stimulus';
import $ from 'jquery';
import toastr from 'toastr';
import 'toastr/toastr.scss';

export default class extends Controller {
    timeout = null;

    static values = {
      endpoint: String,
      waitinglistitemId: Number,
    };

    onKeyUp(event) {
      clearTimeout(this.timeout);
      const that = this;
      const input = event.currentTarget;
      this.timeout = setTimeout(function() {
        that.saveNotes(input);
      }, 500);
    }

    async saveNotes(input) {
      const notes = input.value;

      input.classList.remove('saved');
      input.classList.add('saving');

      $.ajax({
        type: 'POST',
        url: this.endpointValue,
        data: {notes: notes},
      })
          .done(function() {
            input.classList.add('saved');
            input.classList.remove('saving');
            toastr.success('Die Bemerkungen wurden erfolgreich gespeichert!');
          })
      ;
    }
}
