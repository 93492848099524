import {Controller} from 'stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {
    static values = {
      title: String,
      text: String,
      icon: String,
      confirmButtonText: String,
      confirmButtonColor: String,
      cancelButtonText: String,
      cancelButtonColor: String,
      showCancelButton: Boolean,
    }

    onClick(event) {
      event.preventDefault();

      Swal.fire({
        title: this.titleValue || null,
        text: this.textValue || null,
        icon: this.iconValue || null,
        showCancelButton: this.showCancelButtonValue || true,
        confirmButtonText: this.confirmButtonTextValue || 'Ja',
        cancelButtonText: this.cancelButtonTextValue || 'Nein',
        confirmButtonColor: this.confirmButtonColorValue || '#5cd72f',
        cancelButtonColor: this.cancelButtonColor || '#d33',
        focusCancel: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location = this.element.href;
        }
      });
    }
}
