import {Controller} from 'stimulus';
import {useDispatch} from 'stimulus-use';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.min.css';

export default class extends Controller {
  static values = {
    minimumInputLength: Number,
  }
  connect() {
    $(this.element).select2({
      minimumInputLength: this.minimumInputLengthValue || 0,
      theme: 'bootstrap4',
    });

    useDispatch(this);

    const that = this;

    $(this.element).on('change.select2', function() {
      that.dispatch('changed', {element: that.element});
    });

    $(document).on('select2:open', () => {
      document.querySelector('.select2-container--open .select2-search__field').focus();
    });
  }
}
